<template>
  <div class="main_content align-items-center" style="min-height: 80vh">
    <div class="row justify-content-center">
      <div class="row justify-content-between mb_54">
        <div class="col-12 col-md-12 col-xl-5 col-xxl-5 top_heading">
          <div class="heading padd_ipad">
            <h2 style="color: #39353d">Evaluations Requested</h2>
            <h3 style="color: #39353d">Program Manager Dashboard</h3>
          </div>
        </div>
        <div class="col-12 col-md-12 col-xl-7 col-xxl-7 emp_das_datepiker">
          <div class="align-items-center d-flex justify-content-end w-100">
            <div class="date_toolbar_main">
              <div class="bg-white date-toolbar region_bar_add">
                <div class="row me-0">
                  <div class="col-xs-12 col-md-12 col-lg-9 align-self-center">
                    <div class="">
                      <div class="bg-white date-toolbar" style="box-shadow: none">
                        <div class="row">
                          <div
                            class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12"
                          >
                            <div class="row">
                              <div class="col-12 col-md-12 col-lg-6">
                                <div class="date">
                                  <div class="heading_5">From</div>
                                  <div
                                    class="text_black font_size_14 d-flex align-items-center"
                                  >
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.5"
                                        height="17.5"
                                        viewBox="0 0 17.5 17.5"
                                      >
                                        <path
                                          data-name="Path 131"
                                          d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                          transform="translate(-2.25 -2.25)"
                                          style="
                                            fill: none;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-width: 1.5px;
                                          "
                                        />
                                      </svg>
                                    </span>
                                    <datepicker
                                      placeholder="MM DD, YYYY"
                                      v-model="listConfig.start_date"
                                      :format="customFormatter"
                                      @selected="changeStartDate"
                                      :disabledDates="disabledFutureDates"
                                    ></datepicker>
                                    <p class="min-date-value d-none">
                                      {{ listConfig.start_date | formatDate }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 col-md-12 col-lg-6">
                                <div class="date todate pl_6">
                                  <div class="heading_5">To</div>
                                  <div
                                    class="text_black font_size_14 d-flex align-items-center"
                                  >
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.5"
                                        height="17.5"
                                        viewBox="0 0 17.5 17.5"
                                      >
                                        <path
                                          data-name="Path 131"
                                          d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                          transform="translate(-2.25 -2.25)"
                                          style="
                                            fill: none;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-width: 1.5px;
                                          "
                                        />
                                      </svg>
                                    </span>
                                    <datepicker
                                      placeholder="MM DD, YYYY"
                                      v-model="listConfig.end_date"
                                      :format="customFormatter"
                                      @selected="changeEndDate"
                                      :disabled="isDisabled"
                                      :disabledDates="disabledDates"
                                    ></datepicker>
                                    <p class="max-date-value d-none">
                                      {{ listConfig.end_date | formatDate }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-12 col-lg-3 align-self-center">
                    <div class="form-group for_ipad">
                      <v-select
                        @input="getSingleUserRequest"
                        :reduce="(region_list) => region_list.id"
                        :options="region_list"
                        label="name"
                        placeholder="By Region"
                        name="Region"
                        v-model="listConfig.regionBy"
                        v-validate="'required'"
                        browserAutocomplete="false"
                        autocomplete="off"
                      />
                      <small class="error text-danger">
                        {{ errors.first("addUserForm.Region") }}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a href="javascript:" class="reset_btn" @click="resetDates">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000"
                xml:space="preserve"
              >
                <metadata>
                  Svg Vector Icons : http://www.onlinewebfonts.com/icon
                </metadata>
                <g>
                  <path
                    d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z"
                  ></path>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="table_card">
            <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
              <div class="dataTables_length" id="datatable_length">
                <label
                  >Reports
                  <select
                    name="datatable_length"
                    aria-controls="datatable"
                    class=""
                    v-model.number="itemsPerPage"
                    @change="getRequestsBysearch"
                  >
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                    <option>500</option>
                  </select>
                  entries</label
                >
              </div>
              <div
                class="align-items-start d-xl-flex d-md-block justify-content-end er-filter">
                <div
                  id="datatable_filter"
                  class="dataTables_filter mr_20 mr_mini survey-download"
                >
                  <h1 v-if="scheduledCheckbox.length" class="updatestatus">
                    Update Status
                  </h1>
                  <label v-else  class="searchwithregion"
                    ><input
                      type="search"
                      placeholder="Search"
                      aria-controls="datatable"
                      v-model="listConfig.search_string"
                      v-on:keyup.enter="getRequestsBysearch"
                    />
                    <a
                      href="javascript:void(0)"
                      @click="getRequestsBysearch"
                      class="search_icon slty_up"
                      v-if="listConfig.search_string"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        fill="#00"
                        class="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                        />
                      </svg>
                    </a>
                    <a
                      href="javascript:void(0)"
                      v-if="listConfig.search_string"
                      @click="
                        listConfig.search_string = null;
                        getRequestsBysearch();
                      "
                      class="crossicon x_icon"
                    >
                      X
                    </a>
                  </label>
                  <a href="javascript:" class="ml_12 xl_download" v-if="scheduled_request" @click="exportAllData()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                      <g data-name="Group 12689">
                        <path data-name="Path 114"
                          d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                          transform="translate(-3 -3)" style="
                            fill: none;
                            stroke: #1a191c;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 2px;
                          " />
                      </g>
                    </svg>
                    Download All
                  </a>
                </div>
                <div class="region_select status_box new_status status_select i_pad_pro mb-5 mr_20 mr_mini">
                  <v-select :reduce="(status_list) => status_list.id" :options="status_list" label="name"
                    placeholder="All Status" v-model="listConfig.search_status" @input="filterStatus()" />
                </div>
                <!-- <div v-if="scheduledCheckbox.length && oldSelectedCheckbox != 'Hidden'" class="region_select status_box new_status status_select mb-5 ps-2 ps-lg-0">
                  <a href="javascript:" class="comman_icons " @click="updateBulkStatus('Hidden')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16.975" viewBox="0 0 17 16.975">
                      <path id="Path_107" data-name="Path 107"
                        d="M11.431,15.437a7.874,7.874,0,0,1-8.974-5.364A7.836,7.836,0,0,1,3.685,7.693m4.6.714a2.358,2.358,0,0,1,3.335,3.335M8.289,8.406l3.334,3.334M8.291,8.407,5.705,5.821m5.92,5.92,2.586,2.586M2.884,3,5.705,5.821m0,0a7.862,7.862,0,0,1,11.752,4.252,7.879,7.879,0,0,1-3.247,4.253m0,0,2.821,2.821"
                        transform="translate(-1.457 -1.586)" fill="none" stroke="#a9a9a9" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" />
                    </svg>
                    <span class="ml_10"><b>Hide</b></span>
                  </a>
                </div> -->
              </div>
            </div>
            <div class="table-responsive table_audit_log table_hgt150">
              <table class="table table-bordered table dataTable no-footer">
                <thead>
                  <tr>
                    <!-- <th style="min-width: 10px" class="sort">
                      <div class="form_check_box d-flex align-items-center">
                        <input v-if="data_length == 0" type="checkbox" aria-label="..." class="form-check-input ms-0" disabled />
                        <input v-else type="checkbox" v-model="selectAll" @change="checkSelected($event)" aria-label="..." id="flexCheckDefault"
                          class="form-check-input ms-0" />
                        <label for="flexCheckDefault" class=""> </label>
                      </div>
                    </th> -->
                    <th scope="col" class="sort req_for_req">
                      <SortIcons
                        :lable="'REQUEST ON'"
                        :listConfig="listConfig"
                        :sortBy="'created_at'"
                        @sorting="sorting($event)"
                      />
                    </th>
                    <th scope="col" class="sort">
                      <SortIcons
                        :lable="'First Name'"
                        :listConfig="listConfig"
                        :sortBy="'first_name'"
                        @sorting="sorting($event)"
                      />
                    </th>
                    <th scope="col" class="sort req_for_lname">
                      <SortIcons
                        :lable="'Last Name'"
                        :listConfig="listConfig"
                        :sortBy="'last_name'"
                        @sorting="sorting($event)"
                      />
                    </th>
                    <th scope="col" class="sort req_for_email">
                      <SortIcons
                        :lable="'Email Address'"
                        :listConfig="listConfig"
                        :sortBy="'user_email'"
                        @sorting="sorting($event)"
                      />
                    </th>
                    <th scope="col" class="sort">
                      <SortIcons
                        :lable="'Region'"
                        :listConfig="listConfig"
                        :sortBy="'region'"
                        @sorting="sorting($event)"
                      />
                    </th>
                    <th scope="col" class="sort">
                      <SortIcons
                        :lable="'COMFORT'"
                        :listConfig="listConfig"
                        :sortBy="'experiencing_discomfort'"
                        @sorting="sorting($event)"
                      />
                    </th>
                    <th scope="col" class="sort move_icon">
                      <SortIcons
                        :lable="'STATUS'"
                        :listConfig="listConfig"
                        :sortBy="'status'"
                        @sorting="sorting($event)"
                      />
                      <svg
                        style="margin-left: 10px"
                        v-tooltip="
                          'Pending, Scheduled, No Reply, Declined, Hidden, Complete, In Progress, No Show & Rescheduled'
                        "
                        xmlns="http://www.w3.org/2000/svg"
                        data-name="Group 13109"
                        width="16"
                        height="16"
                        viewBox="0 0 14 18.346"
                      >
                        <path
                          data-name="Path 9426"
                          d="M0 0h14v14H0z"
                          style="fill: none"
                        ></path>
                        <path
                          data-name="Path 9427"
                          d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                          transform="translate(-2 -1.654)"
                          style="fill: rgb(109, 109, 109)"
                        ></path>
                      </svg>
                    </th>
                    <th
                      style="text-align: center"
                      scope="col"
                      class="report_action"
                    >
                      ACTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(penReq, indx) in scheduled_request" :key="indx">
                    <!-- <td style="min-width: 10px">
                      <div class="form_check_box d-flex align-items-center">
                        <input type="checkbox" v-model="scheduledCheckbox" :value="penReq.id" aria-label="..." :id="'flexCheckDefault' + indx" class="form-check-input ms-0" @change="checkSelected($event)" />
                        <label :for="'flexCheckDefault' + indx" class="">
                        </label>
                      </div>
                    </td> -->
                    <td>
                      <p class="">{{ penReq.request_on }}</p>
                    </td>
                    <td>
                      <p>{{ penReq.first_name }}</p>
                    </td>
                    <td>
                      <p>{{ penReq.last_name }}</p>
                    </td>
                    <td>
                      <p>{{ penReq.email }}</p>
                    </td>
                    <td>
                      <p>{{ penReq.location }}</p>
                    </td>
                    <td>
                      <p>{{ penReq.comfort }}</p>
                    </td>
                    <td>
                      <p>{{ penReq.status }}</p>
                    </td>
                    <td style="text-align: center">
                      <div class="edit_btn mr_le">
                        <p>
                          <a
                            href="javascript:"
                            @click="viewRequestData(penReq)"
                            class="view-dshboad-btn ergo_table_view"
                            title="View"
                            data-bs-toggle="modal"
                            data-bs-target="#viewRequestData"
                          >
                            <img
                              class="view-icon"
                              :src="
                                require('@/assets/images/dashboard/View_Icon.svg')
                              "
                              alt="img"
                            />
                          </a>
                          <a
                            href="javascript:"
                            @click="allComments(penReq)"
                            class="view-dshboad-btn ergo_table_btn"
                            title="Add Client Note"
                            data-bs-toggle="modal"
                            data-bs-target="#commentModal"
                          >
                            <img
                              style="top: 0px"
                              class="edit-icon"
                              :src="
                                require('@/assets/images/dashboard/note.svg')
                              "
                              alt="img"
                            />
                          </a>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="data_length == 0">
                    <td colspan="8" class="text-center">No Records found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="float-lg-start text-sm-center">
              {{ positionText }}
            </p>
            <VPagination
              v-if="data_length"
              class="dataTables_paginate"
              :totalPages="pages.length"
              :perPage="itemsPerPage"
              :currentPage.sync="currentPage"
              @pagechanged="onPageChange"
            />
          </div>
        </div>
      </div>
      <div
        class="modal fade viewRequestData"
        id="viewRequestData"
        tabindex="-1"
        aria-labelledby="downloadLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="downloadLabel">
                <h3 class="heading_2"><strong> Eval Requests </strong></h3>
              </h5>
              <button
                type="button"
                class="btn-close viewRequestDataBtn"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body details_eval">
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <p>
                    <strong> REQUEST ON : </strong>
                    {{ scheduledRequestModal.request_on }}
                  </p>
                </div>
                <div class="col-xs-12 col-md-6 text-lg-end">
                  <p>
                    <strong> STATUS : </strong>
                    {{ scheduledRequestModal.status }}
                  </p>
                </div>
                <div class="col-xs-12 col-md-6">
                  <p>
                    <strong> NAME : </strong> {{ scheduledRequestModal.name }}
                  </p>
                </div>
                <div class="col-xs-12 col-md-6 text-lg-end">
                  <p>
                    <strong> Contact Number : </strong>
                    {{ scheduledRequestModal.contact_number }}
                  </p>
                </div>
                <div class="col-xs-12 col-md-6">
                  <p>
                    <strong> COMFORT : </strong>
                    {{ scheduledRequestModal.comfort }}
                  </p>
                </div>
                <div class="col-xs-12 col-md-6 text-lg-end">
                  <p>
                    <strong> Discomfort Type : </strong>
                    {{ scheduledRequestModal.discomfort_type }}
                  </p>
                </div>
                <div class="col-xs-12 col-md-12">
                  <p>
                    <strong> EMAIL ADDRESS : </strong>
                    {{ scheduledRequestModal.email }}
                  </p>
                </div>
                <div class="col-xs-12 col-md-12">
                  <p>
                    <strong> REGION : </strong>
                    {{ scheduledRequestModal.location }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade commentModal"
        id="commentModal"
        tabindex="-1"
        aria-labelledby="downloadLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="downloadLabel">
                <h3 class="heading_1">Eval Request Comments</h3>
              </h5>
              <button
                type="button"
                class="btn-close commentModalBtn"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div v-if="allCommentArr.length">
                <div class="row mb-3">
                  <div class="table table-responsive cmt_eva_table cust_scroll">
                    <table class="table" width="100%">
                      <tbody>
                        <tr>
                          <th style="width: 30%">Name</th>
                          <th style="width: 30%">Email</th>
                          <th style="width: 40%">Comment</th>
                        </tr>
                        <tr
                          v-for="(comment, indx) in allCommentArr"
                          :key="indx"
                        >
                          <td valign="middle" style="width: 30%">
                            {{ comment.user_name }}
                          </td>
                          <td valign="middle" style="width: 30%">
                            {{ comment.user_email }}
                          </td>
                          <td valign="middle" style="width: 40%">
                            {{ comment.comments }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div v-if="!allCommentArr.length" class="row">
                <div class="col-xs-12 col-md-12">
                  <h2 class="text-center no_data mb-5">
                    There are no comments
                  </h2>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-md-12">
                  <div class="mb-3 form-group maxheight">
                    <div class="form-group">
                      <textarea
                        class="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Write your comment here"
                        v-model="add_comment"
                        v-validate="'required'"
                        v-on:keyup="countdown"
                        maxlength="1000"
                      />
                      <label class="text-end w-100"
                        >{{ remainingCount }} characters remaining</label
                      >
                    </div>
                  </div>
                  <div v-if="add_comment.length" class="text-center mt_30">
                    <button
                      @click="addComment"
                      class="btn btn_primary mr_10"
                      type="button"
                    >
                      Add Comment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    </div>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import $ from "jquery";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import ConfirmDialogue from "@/components/ConfirmDialogue";
import commonFunction from "../../mixin/commonFunction";
import exportFromJSON from "export-from-json";
import commonService from "../../services/commonService";

export default {
  name: "my_reports",
  components: {
    Datepicker,
    VPagination,
    SortIcons,
    ConfirmDialogue,
  },
  mixins: [commonFunction],
  data() {
    return {
      show: false,
      reports: [],
      isDisabled: true,
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      disabledFutureDates: {
        from: new Date(Date.now()),
      },
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
        search_status: null,
        start_date: null,
        end_date: null,
        regionBy: null,
      },
      region_list: [
        {
          id: "ALL",
          name: "ALL",
        },
      ],
      data_length: 0,
      // status_list: [
      //   {
      //     id: null,
      //     name: "All Status",
      //   },
      //   {
      //     id: "Pending",
      //     name: "Pending",
      //   },
      //   {
      //     id: "Scheduled",
      //     name: "Scheduled",
      //   },
      //   {
      //     id: "No Reply",
      //     name: "No Reply",
      //   },
      //   {
      //     id: "Declined",
      //     name: "Declined",
      //   },
      //   {
      //     id: "Hidden",
      //     name: "Hidden",
      //   },
      // ],
      scheduled_request: [],
      scheduledCheckbox: [],
      dataArr: [],
      scheduledCheckboxArr: {
        status: null,
        checkboxes: [],
      },
      scheduledRequestModal: {
        name: null,
        email: null,
        status: null,
        comfort: null,
        location: null,
        request_on: null,
        contact_number: null,
        discomfort_type: null,
      },
      allCommentArr: [],
      add_comment: "",
      limitmaxCount: 1000,
      maxCount: 1000,
      remainingCount: 1000,
      hasError: false,
      commentArr: [],
      addCommentArr: {
        schedule_id: null,
        comments: null,
      },
      oldSelectedCheckbox: null,
      status_list: commonService.requestReportStatus(),
      previousStartDate: null,
    };
  },
  computed: {
    selectAll: {
      get: function () {
        return this.scheduled_request
          ? this.scheduledCheckbox.length == this.scheduled_request.length
          : false;
      },
      set: function (value) {
        var scheduledCheckbox = [];
        if (value) {
          this.scheduled_request.forEach(function (penReq) {
            scheduledCheckbox.push(penReq.id);
          });
        }
        this.scheduledCheckbox = scheduledCheckbox;
        this.listConfig.search_status = "";
      },
    },
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getSingleUserRequest();
    },
  },
  destroyed() {
    this.$parent.employeeDashboard = false;
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "created_at",
      order: "DESC",
      search_string: null,
      search_status: null,
      start_date: null,
      end_date: null,
      regionBy: null,
    };
    //Get all regions from Client data...
    var clientData = this.$store.getters["auth/authUser"];
    var clientRegions = clientData.client.client_regions.replace(/[^a-zA-Z,/ ]/g, "");
    var clientRegionsArr = clientRegions.split(",");
    for (let i = 0; i < clientRegionsArr.length; i++) {
      this.region_list.push({"id": clientRegionsArr[i], "name": clientRegionsArr[i]});
    }
    this.$parent.employeeDashboard = true;
    this.getSingleUserRequest();
  },
  methods: {
    checkSelected(event) {
      if (event.target.checked) {
        this.listConfig.search_status = "";
      } else {
        if (this.scheduledCheckbox.length == 0) {
          this.listConfig.search_status = this.oldSelectedCheckbox;
        }
      }
    },
    // async updateBulkStatus($event) {
    //   if (this.scheduledCheckbox.length && $event != null) {
    //     const confirmUpdate = await this.$refs.confirmDialogue.show({
    //       title: "Update Status",
    //       message: "Are you sure you want to update status?",
    //       okButton: "Update",
    //     });
    //     if (confirmUpdate) {
    //       this.dataArr = [];
    //       this.scheduledCheckboxArr.status = $event;
    //       this.scheduledCheckboxArr.checkboxes = this.scheduledCheckbox;
    //       this.dataArr.push(this.scheduledCheckboxArr);
    //       if (localStorage.getItem("userToken")) {
    //         var _this = this;
    //         _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
    //         let config = {
    //           method: "post",
    //           url:
    //             process.env.VUE_APP_API_URL + "/api/pending-status-bulk-edit",
    //           headers: {
    //             Authorization: `Bearer ${window.localStorage.getItem(
    //               "userToken"
    //             )}`,
    //           },
    //           data: this.dataArr,
    //         };
    //         _this
    //           .axios(config)
    //           .then(({ data }) => {
    //             if (data.success) {
    //               _this.$toast.success(data.message, {
    //                 position: "top-right",
    //               });
    //             } else {
    //               _this.$toast.error(data.message, {
    //                 position: "top-right",
    //               });
    //             }
    //             _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
    //           })
    //           .catch(() => {
    //             //
    //           });
    //       } else {
    //         this.tokenExpired();
    //       }
    //     }
    //   }
    //   setTimeout(() => {
    //     this.scheduledCheckbox = [];
    //     this.getRequestsBysearch();
    //   }, 100);
    // },
    viewRequestData(penReq) {
      this.scheduledRequestModal.name =
        penReq.first_name + " " + penReq.last_name;
      this.scheduledRequestModal.email = penReq.email;
      this.scheduledRequestModal.status = penReq.status;
      this.scheduledRequestModal.comfort = penReq.comfort;
      this.scheduledRequestModal.location = penReq.location;
      this.scheduledRequestModal.request_on = penReq.request_on;
      this.scheduledRequestModal.contact_number = penReq.contact_number;
      this.scheduledRequestModal.discomfort_type = penReq.discomfort_type;
    },
    allComments(penReq) {
      this.add_comment = "";
      this.remainingCount = 1000;
      this.allCommentArr = [];
      this.fetchAllComments(penReq);
    },
    fetchAllComments(penReq) {
      if (penReq.id) {
        this.commentArr = [];
        this.addCommentArr.schedule_id = penReq.id;
        this.commentArr.push(this.addCommentArr);
        if (localStorage.getItem("userToken")) {
          var _this = this;
          let config = {
            method: "post",
            url:
              process.env.VUE_APP_API_URL + "/api/pending-status-all-comment",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: { request_data: this.encodeAPIRequestWithImages(this.commentArr) },
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                var decodedJson = _this.decodeAPIResponse(data.records.data);
                this.allCommentArr = decodedJson;
              }
            })
            .catch(() => {
              //
            });
        } else {
          this.tokenExpired();
        }
      }
    },
    addComment() {
      if (this.add_comment.length) {
        this.commentArr = [];
        this.addCommentArr.comments = this.add_comment;
        this.commentArr.push(this.addCommentArr);
        if (localStorage.getItem("userToken")) {
          var _this = this;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
          let config = {
            method: "post",
            url:
              process.env.VUE_APP_API_URL + "/api/pending-status-add-comment",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: { request_data: this.encodeAPIRequestWithImages(this.commentArr) },
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                _this.$toast.success(data.message, {
                  position: "top-right",
                });
                document.getElementsByClassName("commentModalBtn")[0].click();
              } else {
                _this.$toast.error(data.message, {
                  position: "top-right",
                });
              }
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            })
            .catch(({response}) => {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              if (response.status == 401) {
                _this.tokenExpired();
              } else {
                _this.$toast.error(response.data.error, {
                  position: "top-right",
                  duration: 5000,
                });
              }
            });
        } else {
          this.tokenExpired();
        }
      }
    },
    countdown: function () {
      this.remainingCount = this.maxCount - this.add_comment.length;
      this.hasError = this.remainingCount < 0;
      if (this.hasError) {
        console.log("Haserror:", this.hasError);
        return;
      }
    },
    resetDates() {
      this.listConfig.end_date = "";
      this.listConfig.start_date = "";
      this.isDisabled = true;
      this.listConfig.regionBy = "";
      this.getSingleUserRequest();
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    sorting($event) {
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getSingleUserRequest();
    },
    getRequestsBysearch() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getSingleUserRequest();
    },
    getSingleUserRequest() {
      var _this = this;
      _this.oldSelectedCheckbox = this.listConfig.search_status;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/dashboard-scheduled-requests",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: _this.encodeAPIRequest(_this.listConfig) },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedJson = _this.decodeAPIResponse(data.data);
          _this.scheduled_request = decodedJson.scheduled_request;
          _this.data_length = decodedJson.scheduled_request_count;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch(({ response }) => {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              if (response.status == 401) {
                _this.tokenExpired();
              } else {
                _this.$toast.error(response.data.error, {
                  position: "top-right",
                  duration: 5000,
                });
              }
            });
    },
    updateDatePicker() {
      // Extend dataTables search
      $.fn.dataTable.ext.search.push(function (settings, data) {
        var min = $(".min-date-value").html();
        var max = $(".max-date-value").html();
        var createdAt = data[0] || 0; // Our date column in the table
        if (
          min == "" ||
          max == "" ||
          (moment(createdAt).isSameOrAfter(min) &&
            moment(createdAt).isSameOrBefore(max))
        ) {
          return true;
        }
        return false;
      });
      this.getRequestsBysearch();
    },
    // changeStartDate() {
    //   this.$nextTick(() => {
    //     this.isDisabled = false;
    //     this.disabledDates = {
    //       to: new Date(this.listConfig.start_date - 8640000),
    //       from: new Date(Date.now()),
    //     };
    //     if (
    //       this.listConfig.end_date != "" &&
    //       this.listConfig.end_date != null
    //     ) {
    //       this.updateDatePicker();
    //     }
    //   });
    // },
    changeStartDate(newStartDate) {
      this.$nextTick(() => {
        const startDate = new Date(newStartDate);
        const endDate = this.listConfig.end_date ? new Date(this.listConfig.end_date) : null;

        if (endDate && startDate > endDate) {
          this.$toast.error('"From" date cannot be greater than the "To" date.', {
              position: "top-right",
              duration: 5000,
            });
          this.listConfig.start_date = this.previousStartDate;
        } else {
          this.previousStartDate = newStartDate;

          // Old changeStartDate code
          this.isDisabled = false;
          this.disabledDates = {
            to: new Date(this.listConfig.start_date - 8640000),
            from: new Date(Date.now()),
          };
          if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
            this.updateDatePicker();
          }
        }
      });
   },
    changeEndDate() {
      this.$nextTick(() => {
        this.updateDatePicker();
      });
    },
    customFormatter(date) {
      return moment(date).format("MMM DD, YYYY");
    },
    async exportAllData() {
      const confirmDownload = await this.$refs.confirmDialogue.show({
        title: "Download",
        message: "Are you sure you want to download?",
        okButton: "Download",
      });

      if (confirmDownload && localStorage.getItem("userToken")) {
        var _this = this;
        _this.$parent.$refs.fullpageloader.is_full_page_loader = true;

        let config = {
          method: "post",
          url:
            process.env.VUE_APP_API_URL +
            "/api/dashboard-scheduled-requests-download-all",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "userToken"
            )}`,
          },
          data: { request_data: _this.encodeAPIRequestWithImages(_this.listConfig) },
        };

        _this.axios(config)
          .then(({ data }) => {
            
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            var downloadResponse = _this.decodeAPIResponse(data.data);
            console.log(downloadResponse);
            var filterExport = [];
            if (downloadResponse) {
              downloadResponse.forEach(function (responseData) {
                filterExport.push({
                  "REQUEST ON": responseData.request_on,
                  "FIRST NAME": responseData.first_name,
                  "LAST NAME": responseData.last_name,
                  "EMAIL ADDRESS": responseData.email,
                  "REGION": responseData.location ? responseData.location : "Not Answered",
                  "COMFORT": responseData.comfort,
                  "STATUS": responseData.status,
                });
              });
              data = filterExport;
            }
            const fileName = "schedulerequests";
            const exportType = exportFromJSON.types.csv;

            if (data) exportFromJSON({ data, fileName, exportType });
          })
          .catch(({ response }) => {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            if (response.status == 401) {
              _this.$router.push({ name: "login" });
            }
          });
      }
    },
    async filterStatus(){
      this.getRequestsBysearch();
    }
  },
};
</script>